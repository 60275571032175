import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: '',
    icon: '',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/dashboard',
    title: 'Books',
    icon: 'mdi mdi-bookmark',
    class: '',
    extralink: false,
    submenu: []
  },
  //{
  //  path: '/component/accordion',
  //  title: 'Create New Chapter',
  //  icon: 'mdi mdi-upload',
  //  class: '',
  //  extralink: false,
  //  submenu: []
  //},
 // {
 //   path: '/component/alert',
 //   title: 'Play Book',
 //   icon: 'mdi mdi-play-circle',
 //   class: '',
//    extralink: false,
//    submenu: []
//  },
  {
    path: '/component/pagination',
    title: 'Manage Organizations',
    icon: 'mdi mdi-view-carousel',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/users',
    title: 'Manage Users',
    icon: 'mdi mdi-calendar-clock',
    class: '',
    extralink: false,
    submenu: []
  },
//  {
//    path: '/component/poptool',
//    title: 'Learning Center Management',
//    icon: 'mdi mdi-tablet',
//    class: '',
//    extralink: false,
//    submenu: []
//  },
  {
    path: '/component/inner-tables',
    title: 'System Tables',
    icon: 'mdi mdi-backburger',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/session-allocation',
    title: 'Session Allocation',
    icon: 'mdi mdi-image-filter-vintage',
    class: '',
    extralink: false,
    submenu: []
  },
 
//  {
//    path: '/component/alert2',
//    title: 'Book Player',
//    icon: 'mdi mdi-poll',
//    class: '',
//    extralink: false,
//    submenu: []
//  },
  {
    path: '/component/settings',
    title: 'settings',
    icon: 'mdi mdi-bandcamp',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/bblmanager',
    title: 'Torah manager',
    icon: 'mdi mdi-sort-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  //{
  //  path: '/component/timepicker',
  //  title: 'Timepicker',
  //  icon: 'mdi mdi-calendar-clock',
  //  class: '',
  //  extralink: false,
  //  submenu: []
  //},
  //{
  //  path: '/component/toast',
  //  title: 'Toast',
  //  icon: 'mdi mdi-alert',
  //  class: '',
  //  extralink: false,
  //  submenu: []
  //}
];
