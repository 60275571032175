<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" dir="ltr" data-theme="light" data-layout="vertical" [attr.data-sidebartype]="options.sidebartype" data-sidebar-position="absolute" data-header-position="fixed" data-boxed-layout="full" [ngClass]="{'show-sidebar': showMobileMenu}">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->
  <header class="topbar" data-navbarbg="skin6">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light" style="background-color:#f2f4f5">
      <div class="navbar-header" [ngClass]="(expandLogo)?'expand-logo':''" data-logobg="skin5">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
          <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/">
          <!-- Logo icon -->
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
            <!-- dark Logo text -->
            <!-- Light Logo text -->
            <img style="width:100px;margin-left:50%" src="assets/images/logo-icon3.png" class="light-logo" alt="homepage" />
          </span>
        </a>

        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)" (click)="isCollapsed = !isCollapsed"
           [attr.aria-expanded]="!isCollapsed" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
          <i class="ti-more"></i>
        </a>
      </div>
      <a class="navbar-brand" href="/" style="float:right;margin-left:70%;margin-top:15px">
        <!-- Logo icon -->
        <!--End Logo icon -->
        <!-- Logo text -->
        <span class="logout-text" style="color:black">
          logout
        </span>
      </a>
      <!--    <div class="thetitle" style="width:65%;margin-top:2%;margin-left:1%;font-size:20px"></div>-->
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->

    </nav>
   
  </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar" data-sidebarbg="skin5" (mouseover)="Logo()" (mouseout)="Logo()">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar" id="menubar" style="visibility:hidden">
            <app-sidebar></app-sidebar>
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <app-breadcrumb></app-breadcrumb>
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            All Rights Reserved by ReadUp!
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
