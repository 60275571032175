import { Component, AfterViewInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {

  public showSearch = false;

  constructor(private modalService: NgbModal) {}


  back() {

     if( (document.getElementById('carteSoudCtrl') as HTMLAudioElement)!=null)
         (document.getElementById('carteSoudCtrl') as HTMLAudioElement).pause();
      window.history.back();
    

  }
  ngAfterViewInit() {}
}
