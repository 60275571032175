import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
	{
		path: '',
		component: FullComponent,
		children: [
          { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

          {
            path: 'login',
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
          },
			{
				path: 'dashboard',
				loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'component',
				loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
			}
		]
	},
	{
		path: '**',
		redirectTo: '/dashboard'
	}
];
