import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

 constructor(private router: Router,private http: HttpClient) {
        setInterval(() => this.mainLoop(), 5000);

}

mainLoop() {
   const credentials = {
      'user': localStorage.usrId,
      'toekn': localStorage.jwt
    }

  if(localStorage.jwt!=null && localStorage.jwt!="null" && localStorage.jwt!="")
{
    this.http.post(document.getElementsByTagName('base')[0].href.replace("/component/", "")+"/api/login/CheckToken", credentials)
      .subscribe(response => {
        if((<any>response).res!="ok")
        {
        localStorage.removeItem('jwt');
        this.router.navigate([".."]);
        
        }
        
          

      }, err => {
      })
}


}
}


